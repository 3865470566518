@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: auto!important;
}

* {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

body {
  font-family: 'Inter', sans-serif;
  @apply text-slate-800
}

.main-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  /* background: url('./img/main-bg.jpg') center top / 3500px no-repeat; */
  z-index: 0;
  opacity: 0.6;
  background-color: #ebebeb;
}

.input-wrap {
  @apply flex flex-col w-full relative mt-4;
}

.input-wrap input {
  @apply z-20 relative
}

.input-wrap label {
  @apply text-gray-600  text-xs absolute top-3 z-10;
  width: 150px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-wrap input:not([type="submit"]) {
  @apply border-b border-wba-secondary text-black text-sm;
  background-color: transparent!important;
  height: 40px;
}

.input-wrap input[type="submit"] {
  @apply text-white bg-wba-primary hover:bg-wba-primary-hover text-center py-2 w-full rounded cursor-pointer mt-2;
}

.input-wrap input[type="submit"]:disabled {
  @apply pointer-events-none opacity-70
}

.input-check {
  @apply absolute right-2 top-3
}

.input-check.valid {
  @apply text-green-500
}

.input-check.invalid {
  @apply text-red-500
}

.instructions {
  @apply absolute top-full left-0 p-2 text-sm rounded-lg bg-blue-200 mt-2 z-50
}

.instructions::after {
  content: '';
  position: absolute;
  left: 10px;
  top: -6px;
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  
  border-bottom: 7px solid rgb(191 219 254);
}

.instructions svg {
  @apply mr-1 text-blue-700
}

.input-check.hide {
  @apply hidden
}

.input-wrap input::placeholder {
  font-size: 0;
}

.input-wrap input:focus-within ~ label,
.input-wrap input:not(:placeholder-shown) ~ label {
  @apply transform scale-80 -translate-y-5 -translate-x-4 text-wba-primary;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.wba-error {
  @apply text-center w-full text-white bg-red-500 rounded py-2 px-2 text-sm;
}

.nav-link {
  @apply flex items-center py-2.5 px-3 font-normal text-wba-gray rounded hover:bg-white cursor-pointer;
}

.nav-link.active {
  @apply bg-gray-300
}

.nav-link.primary {
  @apply text-white bg-wba-primary hover:bg-wba-primary-hover
}

.nav-link svg {
  @apply flex-shrink-0 w-6 h-6 text-wba-gray group-hover:text-wba-gray;
}

.nav-link.primary svg {
  @apply text-white group-hover:text-white
}

/* .nav-link.nuxt-link-exact-active svg {
  @apply text-white group-hover:text-white
} */

.nav-link .info {
  @apply inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full;
}

.nav-link .info-blue {
  @apply inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full;
}

.content-box {
  @apply bg-white rounded border border-gray-300 pt-4 mb-4;
}

.content-box h1 {
  @apply font-bold text-xl;
}

.content-box h2 {
  @apply font-medium text-gray-700 text-sm;
}

.content-box .content-icon {
  font-size: 60px;
  @apply my-4;
}

.status {
  font-size: 10px;
  @apply font-bold uppercase ml-2;
}

.status.--new {
  @apply text-blue-500;
}

.status.--new {
  @apply text-blue-500;
}

.status.--pending {
  @apply text-orange-500;
}

.status.--cancelled {
  @apply text-red-500;
}

.btn {
  @apply flex justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2.5 px-4 border rounded;
}

.btn.disabled{
  @apply opacity-50 bg-red-700 cursor-not-allowed;
}

.btn.red{
  @apply bg-red-600 border-red-600 hover:bg-red-700;
}

.btn.primary {
  @apply bg-wba-primary hover:bg-wba-primary-hover border-wba-primary-hover;
}

.inner-content-box {
  @apply mt-8 pb-8 overflow-auto;
  max-height: 280px;
}

.loader {
	-webkit-animation: spinner 0.75s linear infinite;
	animation: spinner 0.75s linear infinite;
  @apply border-t-wba-primary
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.profile-pic {
  @apply w-full h-full block relative pointer-events-none;
  background-size: cover;
}

.primary-appointment {
  @apply border border-wba-primary;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.dropdown-parent {
  @apply relative z-50
}

.dropdown-box {
  @apply origin-top-right absolute right-0 mt-3 w-52 rounded-md shadow-lg bg-white px-2 py-2 border border-gray-200;
}

.dropdown-box li {
  @apply py-2 px-2 hover:bg-gray-100 rounded cursor-pointer
}

.offscreen {
  position: absolute;
  left: -9999px
}

.errmsg {
  @apply bg-red-500 text-sm text-center py-2 px-2 rounded text-white mb-6 flex justify-center items-center relative
}

.rdp-day_today:not(.rdp-day_selected) {
  background-color: #e2e2e2!important;
}

.profile-pic {
  background: center center / cover no-repeat;
  @apply w-full h-full block relative border border-gray-100 cursor-pointer;
}

.settings-link a {
  @apply bg-white rounded-xl shadow-lg min-h-[150px] flex justify-center items-center flex-col p-4 hover:bg-blue-200
}

.settings-link {
  @apply  w-1/3 px-3 mb-6
}


.settings-link h2 {
  @apply font-bold text-xl text-slate-800
}

.settings-link p {
  @apply mb-6 text-sm text-slate-800
}

.settings-link span {
  @apply w-3/4 pointer-events-none
}

.info-box > form, .info-box > div{
  @apply bg-white rounded-xl shadow-lg min-h-[150px] flex justify-center items-center flex-col p-4;
}

.info-box {
  @apply w-1/3 px-3 mb-6;
}

.info-box input, .info-box select {
  @apply px-4 py-2 block border border-gray-300 rounded-md shadow-md w-3/4 mb-4;
}

.info-box table input {
  @apply w-full;
}

.info-box p {
  @apply mb-6 text-sm text-white
}

.info-box > form > .errmsg {
  @apply mb-4 mt-2 w-3/4;
}

.info-box > div > span, .info-box > form > span {
@apply w-3/4
}

.info-box h2 {
  @apply font-bold text-xl mb-4;
}

.floating-info.error {
  @apply bg-red-500 text-white border-red-600
}

.floating-info.error .icon {
  @apply text-white bg-red-700
}

.floating-info.info {
  @apply bg-blue-500 text-white border-blue-600
}

.floating-info.info .icon {
  @apply text-white bg-blue-700
}

.floating-info.success {
  @apply bg-green-500 text-white border-green-600
}

.floating-info.success .icon {
  @apply text-white bg-green-700
}

.floating-info {
  visibility: visible;
  max-width: 65px;
  height: 65px;
  margin: auto;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  left: 0;right:0;
  bottom: 30px;
  font-size: 17px;
  /* white-space: nowrap; */
  @apply flex items-center rounded-lg shadow-md overflow-hidden
}

.floating-info .icon{
	width: 65px;
	height: 65px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  color: #fff;
  @apply flex justify-center items-center text-2xl
}

.floating-info .text {
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  @apply flex justify-center items-center font-bold
}

.floating-info.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 4s 1s, shrink 0.5s 3s, fadeout 0.5s 3.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s,stay 4s 1s, shrink 0.5s 5s, fadeout 0.5s 5.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
  from {min-width: 65px} 
  to {min-width: 400px;}
}

@keyframes expand {
  from {min-width: 65px}
  to {min-width: 400px}
}
@-webkit-keyframes stay {
  from {min-width: 400px} 
  to {min-width: 400px}
}

@keyframes stay {
  from {min-width: 400px}
  to {min-width: 400px}
}
@-webkit-keyframes shrink {
  from {min-width: 400px;} 
  to {min-width: 65px;}
}

@keyframes shrink {
  from {min-width: 400px;} 
  to {min-width: 65px;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 60px; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 60px; opacity: 0;}
}